import React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";

import withAuth from './withAuth';
import withAdminAuth from './withAdminAuth';

import SignUp from './containers/SignUp';
import SignIn from './containers/SignIn';
import { SignOut } from './containers/SignOut';

import { RedirectOk } from './containers/RedirectOk';
import { RedirectNg } from './containers/RedirectNg';

import SetupPlan from './containers/SetupPlan';
import SetupPayment from './containers/SetupPayment';
import SetupFinish from './containers/SetupFinish';

import Dashboard from './containers/Dashboard';
import ChangePlan from './containers/ChangePlan';
import ChangePayment from './containers/ChangePayment';
import ChangeFinish from './containers/ChangeFinish';
import Billing from './containers/Billing';

import { AdminDashboard } from './containers/AdminDashboard';
import GA  from './utils/GoogleAnalytics';
import MixpanelRedirect from './components/MixpanelRedirect';

function AppRouter() {  
  return (
    <Router>
      {GA.init()}

      <Route exact path="/sign-up" component={GA.withTracker(SignUp)}/>
      <Route exact path="/sign-in" component={GA.withTracker(SignIn)}/>
      <Route exact path="/sign-out" component={GA.withTracker(withAuth(SignOut))}/>

      <Route exact path="/redirect-ok" component={GA.withTracker(RedirectOk)}/>
      <Route exact path="/redirect-ng" component={GA.withTracker(RedirectNg)}/>

      <Route exact path="/dashboard" component={GA.withTracker(withAuth(Dashboard))}/>
      <Route exact path="/change-plan" component={GA.withTracker(withAuth(ChangePlan))}/>
      <Route exact path="/change-payment" component={GA.withTracker(withAuth(ChangePayment))}/>
      <Route exact path="/change-finish" component={GA.withTracker(withAuth(ChangeFinish))}/>
      <Route exact path="/setup-plan" component={GA.withTracker(withAuth(SetupPlan))}/>
      <Route exact path="/setup-payment" component={GA.withTracker(withAuth(SetupPayment))}/>
      <Route exact path="/setup-finish" component={GA.withTracker(withAuth(SetupFinish))}/>
      <Route exact path="/change-plan-test" component={GA.withTracker(ChangePlan)}/>
      <Route exact path="/change-payment-test" component={GA.withTracker(ChangePayment)}/>
      <Route exact path="/change-finish-test" component={GA.withTracker(ChangeFinish)}/>
      <Route exact path="/setup-plan-test" component={GA.withTracker(SetupPlan)}/>
      <Route exact path="/setup-payment-test" component={GA.withTracker(SetupPayment)}/>
      <Route exact path="/setup-finish-test" component={GA.withTracker(SetupFinish)}/>
      <Route exact path="/billing-history" component={GA.withTracker(withAuth(Billing))}/>

      <Route exact path="/admin-dashboard" component={GA.withTracker(withAdminAuth(AdminDashboard))}/>

      <Route exact path="/dashboard-test" component={GA.withTracker(Dashboard)}/>

      <Route exact path="/" component={GA.withTracker(MixpanelRedirect, {}, {redirect: "https://getkiara.com/", message:"rd getkiara.com"})} />
      <Route exact path="/privacy-policy" component={GA.withTracker(MixpanelRedirect, {}, {redirect: "https://getkiara.com/privacy", message:"rd getkiara.com/privacy"})} />
      <Route exact path="/pricing" component={GA.withTracker(MixpanelRedirect, {}, {redirect: "https://getkiara.com/#pricing", message:"rd getkiara.com/#pricing"})} />
      <Route exact path="/terms" component={GA.withTracker(MixpanelRedirect, {}, {redirect: "https://getkiara.com/terms", message:"rd getkiara.com/terms"})} />
      <Route exact path="/help" component={GA.withTracker(MixpanelRedirect, {}, {redirect: "https://getkiara.com/help", message:"rd getkiara.com/help"})} />
      <Route exact path="/contact" component={GA.withTracker(MixpanelRedirect, {}, {redirect: "https://getkiara.com/contact", message:"rd getkiara.com/contact"})} />
      <Route exact path="/support" component={GA.withTracker(MixpanelRedirect, {}, {redirect: "https://getkiara.com/contact", message:"rd getkiara.com/contact"})} />
    </Router>
  );
  
}

export default AppRouter;
