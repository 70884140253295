import React from "react";
import { MixpanelConsumer } from 'react-mixpanel';
import MixpanelTrigger from "./MixpanelTrigger";

class MixpanelOnload extends React.Component {
	render() {
		return  <MixpanelConsumer>
			        {mixpanel => <MixpanelTrigger mixpanel={mixpanel} message={this.props.message} />}
		        </MixpanelConsumer>
    }
}
export default MixpanelOnload;