import React from "react";
import SignInTemplate from "../components/templates/SignInTemplate";
import Typography from "@mui/material/Typography";
import MixpanelOnload from "../components/MixpanelOnload";

export class RedirectNg extends React.Component {
  render() {
    return (
      <SignInTemplate>
        <Typography variant="h5" component="h2">
          Failed...
        </Typography>
        <p>
            We are very sorry that there was an error installing Kiara to your workspace.
            <br/>
            (Please check if you type [add to slack button] at first)
            <br/>
            <br/>
            Please try again.
        </p>
        <MixpanelOnload message="kiara-app.com/redirect-ng" />
      </SignInTemplate>
    )
  }
}
