import React, {Component} from 'react';
import Link from '@mui/material/Link';
import { withStyles } from '@mui/styles';
import {footerCss} from '../styles/footer';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Copyright from './Copyright';

const useStyles = (theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  root: {
    flexGrow: 1,
  },
  link: {
    marginLeft: '2px',
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
});

const footers = [
  {
    title: 'Company',
    description: [
      {key: 'about', title: 'About', link: 'https://kiara.team/'},
    ],
  },
  {
    title: 'Resources',
    description: [
      { key: 'help', title: 'Help', link: 'https://www.getkiara.com/help' },
      { key: 'contact', title: 'Contact us', link: 'https://www.getkiara.com/contact'},
    ],
  },
  {
    title: 'Legal',
    description: [
      { key: 'privacy', title: 'Privacy policy', link: 'https://getkiara.com/privacy'},
      { key: 'terms', title: 'Terms of use', link: 'https://getkiara.com/terms'},
    ],
  },
];

class Footer extends Component {
  render () {
    const { classes } = this.props;
  
    return (
        <footer className={`${footerCss} bg-dark py-5`}>
          <div className="container d-lg-block">
            <div className="row mb-5">
              <div className="col-md-3 text-md-left text-center">
                <img className="img-fluid"
                     src="https://d1pnnwteuly8z3.cloudfront.net/images/bd81baf7-7264-4c44-b960-361f849e8734/c989ced8-9528-43cf-ad6c-37972c211513.png"
                     width="140" />
                  <p className="text-light"></p>
                  <p className="text-light">hello@kiara.team</p>
                  <p className="text-light mt-xl-n3 mt-lg-n3 mt-md-n3 mt-sm-n3 mt-n3">1-6-5 Jinnan, Shibuya-ku, Tokyo</p>
              </div>
              <div className="col-md-3 mb-4 mb-md-0 text-center col-sm-12 col-12">
                <h6 className="mb-4 text-light lead font-weight-bold text-center">
                  <b>Product</b>
                </h6>
                <a className="d-block text-white text-center" href="https://www.getkiara.com#features">Features</a>
                <a className="d-block text-white text-center" href="https://www.getkiara.com/customers">Customers</a>
                <a className="d-block text-white text-center" href="https://www.getkiara.com/help">Help</a>
                <a className="d-block text-white text-center" href="https://www.getkiara.com/updates">Updates</a>
              </div>
              <div className="col-md-3 text-center mb-4 mb-md-0 col-sm-12 col-12">
                <h6 className="mb-4 text-light lead text-center">
                  <b>Company</b>
                </h6>
                <a className="d-block text-white text-center" href="https://kiara.team/" target="_blank">Company
                  Website</a>
                <a className="d-block text-white text-center" href="https://www.getkiara.com/terms">Terms</a>
                <a className="d-block text-white text-center" href="https://www.getkiara.com/privacy">Privacy</a>
                <a className="d-block text-white text-center" href="https://www.getkiara.com/contact">Contact Us</a>
              </div>
              <div className="col-md-3 text-center mb-4 mb-md-0">
                <h6 className="mb-4 text-light text-uppercase font-weight-bold">
                  <b>Connect With Us</b>
                </h6>
                <a className="d-block text-white" href="https://www.facebook.com/kiaraapp" target="_blank">
                  <i className="fab fa-facebook-square fa-2x pr-2"></i>
                </a>
                <a className="d-block text-white" href="https://twitter.com/getkiara" target="_blank">
                  <i className="fab fa-twitter-square fa-2x pr-2"></i>
                </a>
                <a className="d-block text-white" href="https://www.instagram.com/getkiara/" target="_blank">
                  <i className="fab fa-instagram fa-2x pr-2"></i>
                </a>
                <a className="d-block text-white" href="https://www.linkedin.com/company/kiara-inc/" target="_blank">
                  <i className="pr-2 fab fa-linkedin fa-2x"></i>
                </a>
              </div>
            </div>
            <div className="row w-100 justify-content-between text-white">
              <div className="col text-center">
                <p className="mt-xl-n1 mb-xl-0 my-xl-0">
                  <small className="pt-xl-0">© 2019 Kiara Inc. All rights reserved.</small>
                </p>
              </div>
            </div>
          </div>
        </footer>
        )
  }
}

export default withStyles(useStyles)(Footer);