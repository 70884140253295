import React, {Component} from 'react';
import { withStyles } from '@mui/styles';
import SettingsIcon from '@mui/icons-material/Settings';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
//import Button from '@mui/material/Button';
//import IconButton from '@mui/material/IconButton';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import MixpanelIconButton from "../MixpanelIconButton";

import Footer from './Footer';
import HeaderBar from './HeaderBar';

import { MixpanelProvider } from 'react-mixpanel';
import mixpanel from '../../utils/mixpanel';

const useStyles = (theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    maxWidth: '100%',
    marginTop: theme.spacing(1),
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  link: {
    textTransform: 'capitalize',
  },
  signOut: {
    backgroundColor: '#ff2f05',
    color: 'white',
  },
  extraItems: {

  },
});

class DashboardTemplate extends Component {
  render () {
    const { classes } = this.props;
    const { children } = this.props;
    const { prependItems, appendItems } = this.props;
    let { maxWidth } = this.props;
    if (maxWidth == null) {
      maxWidth = 'sm'; // xs sm md lg xl
    }
    
    return (
      <MixpanelProvider mixpanel={mixpanel}>
        <CssBaseline />
        <HeaderBar className={classes.appBar}>
          <MixpanelIconButton mixpanelMessage="kiara_app_com_header_menu_dashboard" href="/dashboard" color="secondary" aria-label="Change Plan" size="small" className={classes.link}>
            <SettingsIcon />
            <Box component="span" display={{ xs: 'none', md: 'inline' }}>Dashboard</Box>            
          </MixpanelIconButton>
          <MixpanelIconButton mixpanelMessage="kiara_app_com_header_menu_change-plan" href="/change-plan" color="secondary" aria-label="Change Plan" size="small" className={classes.link}>
            <PeopleAltOutlinedIcon />
            <Box component="span" display={{ xs: 'none', md: 'inline' }}>Change plan</Box>            
          </MixpanelIconButton>
          <MixpanelIconButton mixpanelMessage="kiara_app_com_header_menu_change-payment" href="/change-payment" color="secondary" aria-label="Change Payment" size="small" className={classes.link}>
            <CreditCardIcon />
            <Box component="span" display={{ xs: 'none', md: 'inline' }}>Change payment</Box>            
          </MixpanelIconButton>
          <MixpanelIconButton mixpanelMessage="kiara_app_com_header_menu_billing-history" href="/billing-history" color="secondary" aria-label="Billing History" size="small" className={classes.link}>
            <AccountBalanceIcon />
            <Box component="span" display={{ xs: 'none', md: 'inline' }}>Billing History</Box>
          </MixpanelIconButton>
          <MixpanelIconButton mixpanelMessage="kiara_app_com_header_menu_sign-out" href="/sign-out" color="primary" aria-label="Sign out" size="small" className={[classes.link, classes.signOut]}>
            <ExitToAppIcon />
            <Box component="span" display={{ xs: 'none', md: 'inline' }}>Sign out</Box>
          </MixpanelIconButton>
        </HeaderBar>
        <Container component="main" maxWidth={maxWidth} className={classes.root}>
          <Box className={classes.extraItems}>
            {prependItems}
          </Box>
          <Card className={classes.paper}>
            {children}
          </Card>
          <Box className={classes.extraItems}>
            {appendItems}
          </Box>
        </Container>
        <Footer />
      </MixpanelProvider>
    );      
  }
}

export default withStyles(useStyles)(DashboardTemplate);