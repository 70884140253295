import { createTheme } from '@mui/material/styles'
        
export const theme = createTheme({  // #1
  palette: {
    primary: {
      light: '#ff2f05',
      main: '#ff2d05',
      dark: '#c71b08',
      contrastText: '#ffffff',
    },
    secondary: {
      light: '#666666',
      main: '#222222',
      dark: '#111111',
      contrastText: '#ffffff',
    },
  },
})