import React from "react";
import MixpanelTrigger from "./MixpanelTrigger";
import mixpanel from '../utils/mixpanel';

class MixpanelRedirect extends React.Component {
	render() {
        console.log('render');
        console.log('redirect')
        console.log(this.props);
        return <MixpanelTrigger mixpanel={mixpanel} message={this.props.message} redirect={this.props.redirect} />        
        //return null
    }
}
export default MixpanelRedirect;