import React from "react";
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { withStyles } from '@mui/styles';

import DashboardTemplate from "../components/templates/DashboardTemplate";
import MixpanelOnload from "../components/MixpanelOnload";

//import PaymentIcon from '@mui/icons-material/PaymentIcon';
import HowToInviteImage from '../img/screenshots/how_to_invite.png';
import HowToAddLanguage from '../img/screenshots/add_language.png';
import HowToSelectButton from '../img/screenshots/setting.png';
import KiaraLogo from '../img/kiara_logo_icon_red.png';
//const moment = require('moment');

const useStyles = theme => ({
    '@global': {
    },
    paper: {
        padding: '8px',
    }
});

class SetupFinish extends React.Component {

    constructor() {
        super();
        this.state = {
        };
    }

    componentDidMount() {
        //this.getAccount();
    }

    prependItems = () => {
        return <div>
        </div>
    }
    appendItems = () => {
        return <Grid container spacing={4} justify="space-evenly" alignItems="top"  justifyContent="center" sx={{ marginTop: '30px' }}>
            <Grid item md={12} align="center">
                <Typography variant="h6" component="p">
                    To setup Kiara on your side, only 3 steps needed...
                </Typography>
            </Grid>
            <Grid item md={4} align="left">
                <Typography variant="h6" component="p">
                    Step 1.
                </Typography>
                <p>Say "@Kiara" to the channel you want to add</p>
                <img src={HowToInviteImage} alt="how to invite on slack" style={{'width': '100%'}} />
            </Grid>
            <Grid item md={4} align="left">
                <Typography variant="h6" component="p">
                    Step 2.
                </Typography>
                <p>Press "Add" button and select language</p>
                <img src={HowToSelectButton} alt="how to select button" style={{'width': '100%'}} /><br />
                <img src={HowToAddLanguage} alt="how to add language" style={{'width': '100%'}} />
            </Grid>
            <Grid item md={4} align="left">
                <Typography variant="h6" component="p">
                    Step 3.
                </Typography>
                <p>Basic settings completed</p>
            </Grid>
        </Grid>
    }

    render() {
        // TODO change trial finish date
        return (
            <DashboardTemplate maxWidth="xl" prependItems={this.prependItems()} appendItems={this.appendItems()}>
                <div>
                    <CardContent align="center">
                        <img src={KiaraLogo} alt="kiara logo" style={{'width': '120px'}} />
                        <Typography variant="h6" component="p">
                            Thank you for your registration.
                        </Typography>
                        <p>
                        Register succeeded.<br />
                        Your free trial will be expired in 14 days.<br />
                        Need support? Please check out our <a href="https://www.getkiara.com/help/category/faq">FAQ</a>.<br />
                        For more information, talk to us by chat, <a href="https://www.getkiara.com/contact">form</a> or to hello@kiara.team
                        </p>
                    </CardContent>
                    <MixpanelOnload message="kiara-app.com/setup-finish" />
                </div>
            </DashboardTemplate>
        )
    }
}
export default withStyles(useStyles)(SetupFinish);
