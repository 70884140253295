import React from "react";
import { Redirect } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
//import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
//import Paper from '@mui/material/Paper';
//import Tooltip from '@mui/material/Tooltip';
//import Divider from '@mui/material/Divider';

import { withStyles } from '@mui/styles';
import DashboardTemplate from "../components/templates/DashboardTemplate";
import MixpanelOnload from "../components/MixpanelOnload";
import MixpanelButton from "../components/MixpanelButton";

import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
//import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
//import PaymentIcon from '@mui/icons-material/PaymentIcon';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import HowToCountImage from '../img/screenshots/how_to_count.png'
import GA from '../utils/GoogleAnalytics';
const moment = require('moment');

const useStyles = theme => ({
    '@global': {
    },
    paper: {
        padding: '8px',
    },
    'catchIcon': {
        fontSize: '3rem !important',
        marginRight: '10px',
        lineHeight: '6rem'
    },
    'calculatedPrice': {
        fontSize: '2rem',
        fontWeight: 'bold',
    },
    sectionTitle: {
        background: '#dddddd',
    }
});

class ChangePlan extends React.Component {

    constructor() {
        super();
        this.state = {
            redirect: false,
            productName: "Loading",
            hasPlan: false,
            hasPaymentMethod: false,
            isTrial: true,
            startTimestamp: new Date().getTime()/1000,
            trialEndTimestamp: new Date().getTime()/1000 + 14 * 24 * 3600 * 1000,
            trialRemains: 14,
            planType: 21,
            productId: 3,
            unitCount: 10,
            // for 
            setPlan: true,
            isOldPlan: false,
            currentUnitCount: 10,
            currentPrice: 30,
            totalPrice: 'loading',
            discountPrice: 30,
            discounted: false,
            nextPaymentDate: '',
        };
        this.getAccount = this.getAccount.bind(this);
    }

    componentDidMount() {
        this.getAccount();
    }

    setCompletePlan() {
        this.setState({
            completePlan: true
        })
    }

    getAccount() {
        fetch('/api/account')
            .then(res => res.json())
            .then(data => {
                // TODO set flags
                console.log(data);
                if (data.planType) {
                    this.setState({planType: data.planType});
                    this.setState({productId: data.productId});
                    this.setState({productName: data.productName});
                    this.setState({currentUnitCount: data.unitCount});
                    this.setState({currentPrice: this.getTotalPrice(data.unitCount, false)})
                    this.setState({nextPaymentDate: moment(data.nextPaymentTimestamp * 1000).format('YYYY-MM-DD')});
                    this.setState({trialEndDate: moment(data.trialEndTimestamp * 1000).format('YYYY-MM-DD')});
                    this.setState({hasPlan: true});
                    this.setState({hasPaymentMethod: data.hasPaymentMethod});
                    this.setState({isOldPlan: data.isOldPlan});
                    this.setState({isTrial: data.isTrial});
                    if (this.state.isOldPlan) {
                        this.setState({setPlan: false});
                        if (this.state.planType === 1) {
                            console.log('set price for personal plan')
                            this.setState({totalPrice: 35});
                            this.setState({currentPrice: 35});
                        }
                        else {
                            console.log('set price for free plan')
                            this.setState({totalPrice: 0});
                            this.setState({currentPrice: 0});
                        }
                        return;
                    }
                    else {
                        this.setState({setPlan: true});
                        this.setUnitCount(data.unitCount);
                    }
                }
                else {
                    this.setState({hasPlan: false});
                    this.setState({setPlan: true});
                    this.setState({isTrial: true});
                    this.setUnitCount(this.state.unitCount);
                }
            })
            .catch(err => {
                console.log(err);
                this.setState({hasPlan: false});
                this.setState({setPlan: true});
                this.setState({isTrial: true});
                this.setUnitCount(this.state.unitCount);
        })
    }

    handleSubmit = event => {
        event.preventDefault();
        if (this.state.productId === 1 || this.state.productId === 2) {
            // old type
        }
        else {
            if (this.state.unitCount < 1) {
                const confirm = window.confirm("Please set user count on your Slack workspace");
                return false;
            }
        }
        GA.Event('Dashboard', 'Change user count', 'changePlan');
        fetch("/api/plan/setPlan", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                'planType': this.state.planType,
                'productId': this.state.productId,
                'unitCount': this.state.unitCount,
            })
        })
        .then((res) => {
            console.log(res);
            console.log(this);
            if (res.status == 200) {
                // do redirect
                this.setState({'redirect': true});
            }
            else {
                alert('something went wrong, please contact through chat');
            }
        })
        .catch((e) => {
            console.log(e);
            alert(e);
        })
    };

    getTotalPrice = (unitCount, withDiscount=true) => {
        if (withDiscount && unitCount >= 100) {
            return unitCount * 240 / 100;
        }
        return unitCount * 3;
    }
    setUnitCount = (newUnitCount) => {
        this.setState({unitCount: newUnitCount});
        const price = this.getTotalPrice(newUnitCount, false);
        this.setState({totalPrice: price});
        if (newUnitCount >= 100) {
            const discountPrice = this.getTotalPrice(newUnitCount, true);
            this.setState({discountPrice: discountPrice, discounted: true});
            this.setState({planType: 21});
            this.setState({productId: 4});
            this.setState({productName: "Kiara Translator for Slack"});
        }
        else {
            this.setState({discountPrice: price, discounted: false});
            this.setState({planType: 21});
            this.setState({productId: 3});
            this.setState({productName: "Kiara Translator for Slack"});
        }
    }

    handleInputChange = (event) => {
        this.setUnitCount(event.target.value === '' ? '' : Number(event.target.value));
    }
    
    handleBlur = () => {
        if (this.unitCount < 0) {
            this.setUnitCount(0);
        }
    }

    trialAlert = () => {
        const {hasPlan, isTrial, trialEndDate} = this.state;
        return (
            ((hasPlan && isTrial && trialEndDate) ? (
                    <Typography className="urgent-notice alert" color="primary" align="right">
                    You're free trial period ends on {trialEndDate}
                    </Typography>
                ) : (
                    <div></div>
                )
            )
        );
    }

    planSelectForm = () => {
        const { unitCount, discountPrice } = this.state;
        const mixpanelMessage = 'kiara_app_com_setup_plan_button';
        return (
            <Grid container spacing={4} justify="space-evenly" alignItems="center"  justifyContent="center">
                <Grid item md={8} sm={10} align="center">
                    <TextField
                    value={unitCount}
                    margin="dense"
                    type="number"
                    variant="standard"
                    placeholder="Enter user count in your Slack workspace"
                    size="medium"
                    sx={{ width: '100%' }}
                    onChange={this.handleInputChange}
                    onBlur={this.handleBlur}
                    /><br />
                    <p>Price for your slack workspace will be:</p>
                    <p class={this.props.classes.calculatedPrice}>
                    ${discountPrice} per month
                    </p>
                    <MixpanelButton mixpanelMessage={mixpanelMessage} variant="contained" color="primary" sx={{ width: '100%' }} onClick={this.handleSubmit}>Change</MixpanelButton>
                    <p>
                        *In the case you pay by credit card every month.<br />
                        If you prefer a 12-months advance payment by invoice, please<br />
                        talk to us by chat, <a href="https://www.getkiara.com/contact">form</a> or to hello@kiara.team.
                    </p>
                </Grid>
            </Grid>
        )
    }

    prependItems = () => {
        return <div>
            { this.trialAlert() }
        </div>
    }
    appendItems = () => {
        return <Grid container spacing={4} justify="space-evenly" alignItems="center"  justifyContent="center" sx={{ marginTop: '30px' }}>
            <Grid item md={8} sm={10} align="center">
            <p>You will need to input credit card info in the next page.</p>
            <Typography variant="h6" component="p">
                Don’t know how to count?
            </Typography>
            <p>
                Count users in #general channel.
            </p>
            <img src={HowToCountImage} alt="how to count on slack" style={{'width': '100%'}} />
        </Grid>
        </Grid>
    }

    // no plan -> urges to select plan
    // no payment -> 
    // under trial -> shows period
    // under free plan -> 
    render() {
        const { redirect } = this.state;
        const { productName, isOldPlan, currentUnitCount, currentPrice } = this.state;
        const { classes } = this.props;

        if (redirect) {
            return  <Redirect to="/change-finish" >
                    </Redirect>
        }
        return (
            <DashboardTemplate prependItems={this.prependItems()} appendItems={this.appendItems()}>
                <div>
                    <CardContent>
                        <h3 className={classes.sectionTitle}>Your Plan</h3>
                        <p>
                            <label>Current Plan: </label>
                            { productName }
                        </p>
                        { isOldPlan ? 
                            <span></span>
                        :
                            <p>
                                <label>User Count: </label>
                                { currentUnitCount }
                            </p>
                        }
                        <p>
                            <label>Monthly payment: </label>
                            ${ currentPrice }
                        </p>
                        <h3 className={classes.sectionTitle}>Change Plan</h3>
                        <Typography variant="h5" component="h2" align="center">
                            Enter the user count below to set up your account.
                        </Typography>
                        { this.planSelectForm() }
                    </CardContent>
                    <MixpanelOnload message="kiara-app.com/change-plan" />
                </div>
            </DashboardTemplate>
        )
    }
}
export default withStyles(useStyles)(ChangePlan);
