import React from "react";
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
//import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
//import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined';
import AnnouncementOutlinedIcon from '@mui/icons-material/AnnouncementOutlined';
import UpgradeOutlinedIcon from '@mui/icons-material/UpgradeOutlined';
import { styled } from '@mui/system';

import DashboardTemplate from "../components/templates/DashboardTemplate";
import MixpanelOnload from "../components/MixpanelOnload";
import MixpanelIconButton from "../components/MixpanelIconButton";
//import GA from '../utils/GoogleAnalytics';
const moment = require('moment');

const DashboardButton = styled(MixpanelIconButton)(({ theme }) => ({
    width: '160px',
    height: '160px',
    padding: '0',
    paddingTop: '20px',
    margin: '10px',
    float: 'left',
    borderRadius: '10px',
    borderColor: '#333333',
    borderStyle: 'solid',
    borderWidth: '1px',
    display: 'block',
    '& .MuiSvgIcon-root': {
        width: '160px',
        height: '100px',
        display: 'block',
    },
    '& .MuiBox-root': {
        width: '160px',
        fontSize: '16px',
        height: '20px',
        display: 'block',
    },
}));

class Dashboard extends React.Component {

    constructor() {
        super();
        this.state = {
            stripe: null,
            planType: "",
            productId: "",
            productName: "",
            unitCount: "",
            isOldPlan: false,
            isTrial: true,
            trialEndDate: '',
            featureDm: false
        };
        this.getAccount = this.getAccount.bind(this);
    }

    componentDidMount() {
        this.getAccount();
    }

    getAccount() {
        fetch('/api/account')
            .then(res => res.json())
            .then(data => {
                if (data.planType) {
                    this.setState({planType: data.planType});
                    this.setState({productId: data.productId});
                    this.setState({productName: data.productName});
                    this.setState({unitCount: data.unitCount});
                    this.setState({nextPaymentDate: moment(data.nextPaymentTimestamp * 1000).format('YYYY-MM-DD')});
                    this.setState({isOldPlan: data.isOldPlan});
                    this.setState({isTrial: data.isTrial});
                    this.setState({trialEndDate: moment(data.trialEndTimestamp * 1000).format('YYYY-MM-DD')});
                    this.setState({featureDm: data.featureDm});
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    // no plan -> urges to select plan
    // no payment -> urges to set payment (+14 days from registration)
    // under trial -> shows period
    // under free plan -> shows character count limit
    render() {
        const {productName, unitCount, isOldPlan, isTrial, trialEndDate, featureDm} = this.state;
        return (
            <DashboardTemplate>
                <Typography variant="h5" component="h2">
                    My page
                </Typography>
                <Paper elevation={0}>
                    <p>Thank you for using Kiara Translator for Slack </p>
                    <p>
                        Your current setting:
                    </p>
                    <Grid container alignItem="center">
                        <Grid item xs={8}>
                            <Paper elevation={1}>
                                <ul>
                                    <li>Plan: <strong>{ productName }</strong></li>
                                    {
                                        isOldPlan ?
                                        null :
                                        <li>User count: { unitCount }</li>
                                    }
                                    {
                                        isTrial ?
                                        <li>Trial: ends on {trialEndDate}</li>
                                        : null
                                    }
                                </ul>
                            </Paper>
                        </Grid>
                    </Grid>
                    <br />
                    { 0 ? 
                    <DashboardButton mixpanelMessage="kiara_app_com_header_dashboard_change-plan" size="large" aria-label="Change Plan" href="/change-plan">
                        <AccountCircleOutlinedIcon fontSize="inherit" />
                        <Box display={{ xs: 'none', md: 'inline' }}>Change user count</Box>            
                    </DashboardButton>
                    : <div></div>
                    }
                    <DashboardButton mixpanelMessage="kiara_app_com_header_dashboard_change-plan" size="large" aria-label="Change Plan" href="/change-plan">
                        <PeopleAltOutlinedIcon fontSize="inherit" />
                        <Box display={{ xs: 'none', md: 'inline' }}>Change user count</Box>            
                    </DashboardButton>
                    <DashboardButton mixpanelMessage="kiara_app_com_header_dashboard_change-payment" aria-label="Change Payment" href="/change-payment">
                        <CreditCardOutlinedIcon fontSize="inherit" />
                        <Box display={{ xs: 'none', md: 'inline' }}>Update credit card</Box>            
                    </DashboardButton>
                    <DashboardButton mixpanelMessage="kiara_app_com_header_dashboard_billing-history" aria-label="Billing history" href="/billing-history">
                        <ReceiptOutlinedIcon fontSize="inherit" />
                        <Box display={{ xs: 'none', md: 'inline' }}>Billing history</Box>                                    
                    </DashboardButton>
                    <DashboardButton mixpanelMessage="kiara_app_com_header_dashboard_contact" aria-label="Contact us"  href="/contact">
                        <ContactSupportOutlinedIcon fontSize="inherit" />
                        <Box display={{ xs: 'none', md: 'inline' }}>Contact us</Box>            
                    </DashboardButton>
                    <DashboardButton mixpanelMessage="kiara_app_com_header_dashboard_change-log" aria-label="Change log"  href="https://getkiara.com/news">
                        <AnnouncementOutlinedIcon fontSize="inherit" />
                        <Box display={{ xs: 'none', md: 'inline' }}>Change log</Box>            
                    </DashboardButton>
                    { (!featureDm) ? 
                    <DashboardButton mixpanelMessage="kiara_app_com_header_dashboard_upgrade" aria-label="Upgrade feature" href="/api/slack/upgrade">
                        <UpgradeOutlinedIcon fontSize="inherit" />
                        <Box display={{ xs: 'none', md: 'inline' }}>Upgrade features</Box>            
                    </DashboardButton>
                    : <div></div>
                    }
                </Paper>
                <MixpanelOnload message="kiara-app.com/dashboard" />
            </DashboardTemplate>
        )
    }
}

export default Dashboard;
