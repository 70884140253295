import React, {Component} from 'react';
import { injectStripe, CardNumberElement,
    CardExpiryElement,
    CardCVCElement} from 'react-stripe-elements';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import HelpIcon from '@mui/icons-material/Help';
import MixpanelButton from "../components/MixpanelButton";
import MixpanelTextField from "../components/MixpanelTextField";

import StripeInput from '../components/StripeInput';

import GA from '../utils/GoogleAnalytics';
import cvcTip from '../img/cvcTip.jpeg';
//import visaCardImage from '../img/cards/VisaBlue.svg';
//import masterCardImage from '../img/cards/mc_vrt_pos.svg';
//import amexCardImage from '../img/cards/Amex_logo_color.svg';
import allCardsImage from '../img/cards/3_Card_color_horizontal.svg';

class CheckoutForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            processingPayment: false,
            email: '',
            planId: this.props.planId,
            skipPayment: false,
        };
        this.submit = this.submit.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
    }
    componentDidUpdate(prevProps) {
        const keyList = ['email'];
        const updates = {};
        let ct = 0;
        //console.log(['didUpdate', prevProps, this.props]);
        for (let i in keyList) {
            const k = keyList[i];
            if (prevProps[k] != this.props[k]) {
                updates[k] = this.props[k];
                ct++;
            }
        }
        if (ct > 0){
            //console.log(updates);
            this.setState(updates);
        }
    }
    handleEmailChange(event) {
        this.setState({ email: event.target.value });
    }

    async submit(ev, mixpanel) {
        const {hasPaymentMethod, planId } = this.props;
        const {skipPayment} = this.state;
        this.setState({processingPayment: true})
        let msg = "Are you sure want to register credit card for payment?";
        let gaActions = "Register credit card detail";
        if ( skipPayment ) {
            msg = "Please setup payment later from dashboard.";
            gaActions = "Finish without payment";
        }
        else if (hasPaymentMethod) {
            msg = "Are you sure want to change your credit card?";
            gaActions = "Change credit card detail";
        }
        if (mixpanel) {
            mixpanel.track('kiara_app_com_try_pay');
        }
        const confirm = window.confirm(msg);
        if (confirm) {
            GA.Event('Dashboard', gaActions, 'Payment'); // TODO
            let {token} = (skipPayment) ? 
                {token: null} :
                await this.props.stripe.createToken({name: "Kiara-Payment"});
            if (mixpanel) {
                if (skipPayment) {
                    mixpanel.track('kiara_app_com_skip_pay');
                }
                else if (token) {
                    mixpanel.track('kiara_app_com_pay_ok');
                }
                else {
                    mixpanel.track('kiara_app_com_pay_fail');
                }
            }
            console.log(token);
            // TODO check value
            const formData = {
                'email': this.state.email
            };
            console.log(this.props);
            console.log(this.state);
            console.log(planId);
            const postData = 
                JSON.stringify({
                    'planId': planId,
                    'skipPayment': skipPayment,
                    'source': token, // TODO
                    'formData': formData
                });
            console.log(postData);
            let response = await fetch("/api/plan/setPayment", {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: postData}
            );
            this.setState({processingPayment: false});
            if (response.ok) {
                this.props.setCompletePayment();
                if (mixpanel) {
                    mixpanel.track('kiara_app_com_set_payment_ok');
                }
            }
            else {
                if (mixpanel) {
                    mixpanel.track('kiara_app_com_set_payment_fail');
                }
            }
        }
        else {
            this.setState({processingPayment: false});
            if (mixpanel) {
                mixpanel.track('kiara_app_com_pay_no_confirm');
            }
        }
    }

    toggleSkipPayment () {
        if (this.state.skipPayment) {
            this.setState({skipPayment: false})
        }
        else {
            this.setState({skipPayment: true})
        }
    }

    cvcInfo () {
        return <Typography>
            3-digit security code usually found on the back of your card.
            <img src={cvcTip} alt="cvc-tip" style={ { display: "block" }}/>
        </Typography>
    }

    getMainForm () {
        const { isTrial } = this.props;
        const { skipPayment } = this.state;
        if (isTrial && skipPayment) {
            return null;
        }
        return (
            <React.Fragment>
                <Grid item xs={12}>
                    <img src={allCardsImage} alt="American Express VISA Master Card" width="360"/>
                </Grid>
                <Grid item xs={12}>
                    <div className="user-form-email">
                        <MixpanelTextField
                            mixpanelMessage="kiara_app_com_select_plan_put_email"
                            label="Email"
                            type="email"
                            value={this.state.email}
                            onChange={this.handleEmailChange}
                            id="email"
                            name="email"
                            placeholder="Your email"
                            variant="standard"
                            required
                            fullWidth
                                />
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <MixpanelTextField
                        mixpanelMessage="kiara_app_com_select_plan_put_card_number"
                        label="Credit Card Number"
                        name="ccnumber"
                        variant="standard"
                        required
                        fullWidth
                        InputProps={{
                            inputComponent: StripeInput,
                            inputProps: {
                                component: CardNumberElement
                            },
                        }}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <MixpanelTextField
                        mixpanelMessage="kiara_app_com_select_plan_put_expiration"
                        label="Card Expiration Date"
                        name="ccexp"
                        variant="standard"
                        required
                        fullWidth
                        InputProps={{
                            inputProps: {
                                component: CardExpiryElement
                            },
                            inputComponent: StripeInput
                        }}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={5}>
                    <MixpanelTextField
                        mixpanelMessage="kiara_app_com_select_plan_put_cvc"
                        label="CVC"
                        name="cvc"
                        variant="standard"
                        required
                        fullWidth
                        InputProps={{
                            inputProps: {
                                component: CardCVCElement
                            },
                            inputComponent: StripeInput
                        }}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={1}>
                    <Tooltip title={this.cvcInfo() }>
                        <HelpIcon />
                    </Tooltip>
                </Grid>
            </React.Fragment>
        );
    }

    render() {
        console.log(this)
        const { hasPaymentMethod, isTrial, canSkip } = this.props;
        const { skipPayment } = this.state;

        let skipText = null;
        if (isTrial && canSkip) {
            skipText = <div className="user-form">
            <input type="checkbox" value={true} onClick={this.toggleSkipPayment.bind(this)} />Skip now<br />
            </div>;
        }
        let submitMessage = 'Update Plan and Payment';
        let mixpanelMessage = 'kiara_app_com_select_plan_update_and_payment_button';
        //console.log('set submit button');
        //console.log(skipPayment);
        if (!skipPayment) {
            if (hasPaymentMethod ) {
                submitMessage = 'Update Payment';
                mixpanelMessage = 'kiara_app_com_select_plan_update_payment_button';
            }
            else {
                submitMessage = 'Register Payment';
                mixpanelMessage = 'kiara_app_com_select_plan_register_plan_button';
            }
        }
        else {
            submitMessage = 'Confirm';
        }

        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    {skipText}
                </Grid>

                {this.getMainForm()}

                <Grid item xs={12}>
                    <MixpanelButton mixpanelMessage={mixpanelMessage} color="primary" variant="contained" disabled={this.state.processingPayment} onClick={this.submit} sx={{ width: '100%'}}>
                        {submitMessage}
                    </MixpanelButton>
                </Grid>
            </Grid>
        );
    }
}

CheckoutForm.defaultProps = {
}

export default injectStripe(CheckoutForm);
