import React from "react";
import IconButton from '@mui/material/IconButton';
import { MixpanelConsumer } from 'react-mixpanel';

/* This component overrides Material UI Button component
 * and enable to send tracking message to mixpanel
 * additional parameters
 * - mixpanelMessage: message(event) to send
 * - mixpanelTrack: original track function for sending message (e, mixpanel, message)
 */
class MixpanelIconButton extends React.Component {
    onClick=null;
    clickWithTracking = (e, mixpanel) => {
        if (this.props.mixpanelTrack) {
            this.props.mixpanelTrack(e, mixpanel, this.props.mixpanelMessage);
        }
        else {
            mixpanel.track(this.props.mixpanelMessage);
        }
        if (this.onClick) {
            return this.onClick(e, mixpanel);
        }
        return;
    }
	render() {
        const propsCopy = {};
        Object.assign(propsCopy, this.props);
        this.onClick = this.props.onClick
        if (propsCopy.hasOwnProperty('onClick')) {
            delete propsCopy['onClick'];
        }
        console.log(this);
        return <MixpanelConsumer>
            { mixpanel => <IconButton {...propsCopy} onClick={ e => this.clickWithTracking(e, mixpanel) } />}
        </MixpanelConsumer>
    }
}
export default MixpanelIconButton;