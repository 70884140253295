import React from "react";

class MixpanelTrigger extends React.Component {
    componentDidMount() {
        console.log('trigger didMount');
        console.log(this.props);
        //console.log(document.location.search);
        const urlParams = new URLSearchParams(document.location.search);
        //console.log(urlParams.get('mdid'));
        // catch mdid and merge
        let external_distinct_id = urlParams.get('mdid');
        let distinct_id = this.props.mixpanel.get_distinct_id();
        //console.log(distinct_id);
        if (external_distinct_id !== null) {
            // use external_distinct_id as main
            this.props.mixpanel.identify(external_distinct_id);
            this.props.mixpanel.alias(external_distinct_id, distinct_id);
        }
        this.props.mixpanel.track(this.props.message);
    }
    render() {
        console.log('trigger render');
        if (this.props.redirect) {
            window.location.href=this.props.redirect;
            return null;
        }
        return <div></div>;  
    }
}

export default MixpanelTrigger;